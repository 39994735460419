// App specific contants
// Follows PascalCase for names and member
export enum App {
  BranchWindowKey = 'branch',
  SegmentAnalyticsWindowKey = 'analytics',
  InstagramWindowKey = 'instgrm',
  Wag = 'Wag!',
  TwitterUsername = '@WagWalking',
}
// eslint-disable-next-line prefer-destructuring
export const NODE_ENV = process.env.NODE_ENV;
export const { APP_ENV } = process.env;

export const STRIPE_APPLE_PAY_SCRIPT_URL = 'https://js.stripe.com/v2/';

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

// Wag!
export const WAG_WEBAPP_URL = process.env.NEXT_PUBLIC_WAG_WEBAPP_URL;
export const AUTH_TOKEN_SUBDOMAIN = process.env.NEXT_PUBLIC_AUTH_TOKEN_SUBDOMAIN;
export const WAG_CONTENT_BASE_URL = process.env.NEXT_PUBLIC_WAG_CONTENT_BASE_URL;
export const WAG_WALKING_ASSETS_BASE_URL = process.env.NEXT_PUBLIC_WAG_ASSETS_BASE_URL;
export const REGISTRATION_SOURCE = process.env.NEXT_PUBLIC_REGISTRATION_SOURCE;
export const SIGNUP_FLOW_SOURCE = process.env.NEXT_PUBLIC_SIGNUP_FLOW_SOURCE;
export const WAG_GATSBY_URL = process.env.NEXT_PUBLIC_WAG_GATSBY_URL;
export const WAG_HELP_CENTER_URL = process.env.NEXT_PUBLIC_WAG_HELP_CENTER_URL;
export const PREMIUM_SUBSCRIPTION_PRICE = process.env.NEXT_PUBLIC_PREMIUM_SUBSCRIPTION_PRICE
  || 9.99;
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH = (
  process.env.NEXT_PUBLIC_PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH || 7.99
);
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE = (
  process.env.NEXT_PUBLIC_PREMIUM_SUBSCRIPTION_ANNUAL_PRICE || 95.99
);

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const WAG_SAFETY_BASE_URL = process.env.NEXT_PUBLIC_WAG_SAFETY_BASE_URL;
export const CAT_SITTING_HERO_CTA = 'https://wagwalking.app.link/O0Xrw5HzHab';
export const DOWNLOAD_APP_CTA = 'https://wagwalking.app.link/52uv3K5W8jb';
export const VET_CHAT_APP_CTA = 'https://wagwalking.app.link/wF7E5rQP1kb';
export const WAG_WEBAPP_BOOK_TRAINING_URL = 'https://wagwalking.app.link/ZpyPcRUDqub';
export const WELLNESS_REIMBURSEMENT_URL = 'https://wagwalking.kustomer.help/contact/wag-wellness-reimbursement-SkrAemJ8F';

export const MULI_FONT_S3_URL = 'https://assets.wagwalkingweb.com/fonts/muli-v16-latin-regular.woff2';

// Stripe
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
// eslint-disable-next-line max-len
export const PUPPY_TRAINING_GUIDE_STRIPE_PRICE_ID = process.env.NEXT_PUBLIC_PUPPY_TRAINING_GUIDE_STRIPE_PRICE_ID;

// Google
export const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

// Facebook
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

// Split
export const SPLIT_API_KEY = process.env.NEXT_PUBLIC_SPLIT_API_KEY;

// Rollbar
export const ROLLBAR_ENV = process.env.NEXT_PUBLIC_ROLLBAR_ENV;
export const ROLLBAR_CLIENT_TOKEN = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;
// eslint-disable-next-line prefer-destructuring
export const ROLLBAR_SERVER_TOKEN = process.env.ROLLBAR_SERVER_TOKEN;

// Segment
export const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;

// Branch
export const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY;

export const BookingFlowPaths = {
  AddPetInfo: '/booking-flow/add-pet-info',
  SelectServiceType: '/booking-flow/service-type',
  VisitServiceDetails: '/booking-flow/visit-service-details',
  OvernightServiceDetails: '/booking-flow/overnight-service-details',
  OvernightServicePreferences: '/booking-flow/overnight-service-preferences',
  AddPaymentInfo: '/booking-flow/add-payment-info',
  CompleteBooking: '/booking-flow/complete-booking',
  BookingConfirmation: '/booking-flow/booking-confirmation',
};

export const AuthenticationPaths = {
  CodeEntry: '/authentication/code-entry',
};

export const WagWebUrl = Object.freeze({
  Home: BASE_URL,
});

export const WagWebAppUrl = Object.freeze(
  {
    SignupFlow: `${WAG_WEBAPP_URL}/signup`,
    EmbedSignupFlow: `${WAG_WEBAPP_URL}/embed/signup`,
    PremiumFlow: `${WAG_WEBAPP_URL}/premium/checkout`,
    WellnessDiamondFlow: `${WAG_WEBAPP_URL}/wellness/plans?wellness_plan=3`,
    WellnessGoldFlow: `${WAG_WEBAPP_URL}/wellness/plans?wellness_plan=1`,
    WellnessPlatinumFlow: `${WAG_WEBAPP_URL}/wellness/plans?wellness_plan=2`,
    WellnessPremiumFlow: `${WAG_WEBAPP_URL}/wellness/premium`,
    WagHealthVetChatCheckout: `${WAG_WEBAPP_URL}/wag-health/checkout`,
    WellnesssAccountFlow: `${WAG_WEBAPP_URL}/v2/wellness/account`,
    WalkerSignupFlow: `${WAG_WEBAPP_URL}/walker/signup`,
  },
);

// TODO - Change the `SeverityOptions` values
// Since we have this enum declared already, we should
// update the `SeverityOptions`' values to use this instead
// so they will have the same source of string
export enum SeverityOption {
  Fair = 'Fair',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Serious = 'Serious',
  Critical = 'Critical',
}
export enum UserRoleType {
  Owner = 'owner',
  Walker = 'walker',
}

export enum AllowedMethods {
  CodeEmail = 'code-email',
}

export enum SeeMoreHref {
  Condition = 'https://wagwalking.com/condition/{{slug}}',
  Symptom = 'https://wagwalking.com/symptom/{{slug}}',
  Treatment = 'https://wagwalking.com/treatment/{{slug}}',
}
// MUST be unique
export const SeverityOptions = [
  'Fair',
  'Mild',
  'Moderate',
  'Serious',
  'Critical',
];

export enum HealthArticleType {
  Condition = 'Condition',
  Treatment = 'Treatment',
  Symptom = 'Symptom',
}

export enum S3BucketDestination {
  // Health vet form, organic/non-organic vet form will use this bucket dest
  PremiumHealth = 'premium_health',
  DailyBlogComment = 'daily_blog_comment',
  BehaviorArticleComment = 'behavior_article_comment',
}

// HTML DOM id list
// Deprecated - Let's use the enum `HtmlDomId` for easier management
export const GIFT_CARD_FORM_SECTION_ID = 'gift-card-form';
export const HEALTH_ARTICLE_ASK_A_VET_ELEMENT_ID = 'health-article-ask-a-vet';
export const VETTED_DOG_TRAINERS_LIST_SECTION_ELEMENT_ID = 'vetted-dog-trainers-list-section';
export const VETTED_DOG_SITTERS_LIST_SECTION_ELEMENT_ID = 'vetted-dog-sitters-list-section';
export const VETTED_DOG_BOARDERS_LIST_SECTION_ELEMENT_ID = 'vetted-dog-boarders-list-section';
export const HEALTH_ARTICLE_SYMPTOM_SECTION = 'symptoms';
export const HEALTH_ARTICLE_ADVICE_SECTION = 'advice';
export const HEALTH_ARTICLE_EXPERIENCE_LIST_SECTION = 'experience-list-section';
export const STATE_OF_PET_INDUSTRY_DATA_SECTION = 'state-of-pet-industry-data-section';
export const ABOUT_LEADERSHIP_SECTION_ELEMENT_ID = 'about-leadership-section';
export const WELLNESS_HERO_CTA_ELEMENT_ID = 'wellness-hero-cta';

/**
 * Moving to a readonly map object makes it easy to locate
 * and access all the relevant ids (grouping them together)
 */
export enum HtmlDomId {
  BecomeASitterApplicationForm = 'become-a-sitter-application-form',
  DogeCoinBannerSection = 'doge-coin-banner-section',
  ArticleCommentsSection = 'comments-section',
  AnnualPremiumGiftFormSection = 'annual-premium-gift-form-section',
}

export const MINIMUM_PASSWORD_LENGTH = 8;
export const RECOMMENDATIONS_TO_SHOW_WHEN_CLAMPED = 3;

export enum SessionStorageKey {
  AuthToken = 'authToken',
}

export enum Vote {
  No = 'no',
  Yes = 'yes',
}

export enum WalkerProfile {
  ReviewsPerPage = 10,
  RecommendationsPerPage = 10
}

export enum ProviderServiceType {
  Training = 'training',
  Sitting = 'sitting',
  Boarding = 'boarding',
  Walking = 'walking',
}

// Service types that the api/web/providers endpoints accept
export enum ServiceTypes {
    All ='all',
    Boarding = 'boarding',
    DropIn = 'drop-in',
    Sitting = 'sitting',
    Training = 'training',
    Walk = 'walk',
}

export enum MiddleContentSectionDogWalkingLinks {
  HowOftenShouldYouWalk = 'https://wagwalking.com/daily/often-walk-dog-2',
  DoesWalkingMyDogCountAsExercise = 'https://wagwalking.com/daily/does-walking-my-dog-really-count-as-exercise',
  StiffJoints = 'https://wagwalking.com/condition/stiffness-and-inflammation',
  Greyhound = 'https://wagwalking.com/breed/greyhound',
  Pug = 'https://wagwalking.com/breed/pug',
  ObesityInDogs = 'https://wagwalking.com/condition/obesity',
  HeartDisease = 'https://wagwalking.com/condition/heart-disease-of-the-sinus-node',
  Diabetes = 'https://wagwalking.com/condition/diabetes'
}

export enum PastQuestionStatus {
  Pending = 'Pending',
  InReview = 'In Review',
  Answered = 'Answered',
}

export enum ServiceLandingDownloadLinks {
  Overnight = 'https://wagwalking.app.link/7mYOn5BAstb',
  Training = 'https://wagwalking.app.link/tAWVjYrhz4',
  Walking = ' https://wagwalking.app.link/tjqD3qxAstb',
  Grooming = 'https://wagwalking.app.link/xusrBmPQrCb',
}

export enum BookTrainerAppLink {
  AppStore = 'https://wagwalking.app.link/0cjJbmErU8',
  PlayStore = 'https://wagwalking.app.link/YSAxxVArU8'
}

export enum AppLandingPageAppLink {
  AppStore = 'https://apps.apple.com/us/app/wag-dog-walkers-sitters/id940734609',
  PlayStore = 'https://play.google.com/store/apps/details?id=com.ionicframework.wagandroid554504&hl=en_US'
}

export enum BehavioristOrganicPageSuccessAppLink {
  AppStore = 'https://wagwalking.app.link/YHWPmh2xgdb',
  PlayStore = 'https://wagwalking.app.link/YHWPmh2xgdb',
}

export enum DogWalkerAppLink {
  AppStore = 'https://wagwalker.app.link/taZlcYVTPib',
  PlayStore = 'https://wagwalker.app.link/taZlcYVTPib',
}

export enum VideoLink {
  DogWalkingService = 'https://www.youtube.com/embed/fsktE7Xi_48',
  DogSittingService = 'https://www.youtube.com/embed/BRY7JZbLfEM',
  DogBoardingService = 'https://www.youtube.com/embed/GQe8ivEVp7Y',
  DropInsService = 'https://www.youtube.com/embed/wmdBoGccaOE',
  InHomeTrainingService = 'https://www.youtube.com/embed/ISX9gILbg6U',
  DigitalTrainingService = 'https://www.youtube.com/embed/-MRLFSHp8qY',
  BecomeAWalkerPartnerProgramSection = 'https://www.youtube.com/watch?v=MMZr-axNQQY',
}

export enum SocialShareUrls {
  TweetButtonIntentUrl = 'https://twitter.com/intent/tweet',
  PinterestButtonUrl = 'https://www.pinterest.com/pin/create/button/',
}

export enum MaxNumberOfPhotosAccepted {
  ExpertForm = 3,
  DailyWagArticleCommentForm = 3,
  ArticleCommentForm = 3,
}

export enum SplitTreatments {
  On = 'on',
  Off = 'off',
  Control = 'control',
}

export enum MaxNumberOfCharactersAccepted {
  ArticleCommentForm = 250,
  DailyWagArticleCommentForm = 250
}

export enum WagWellnessLinks {
  Petted = 'https://petted.com/?utm_campaign=wag-wellness&utm_content=wag-wellness&utm_medium=web&utm_source=wag&utm_term=content-block',
  WagWellness = '/wag-wellness/wellness-plans',
  WagHealth = '/wag-health',
}

export enum WellnessCmsComponents {
  PetInsurerWithFeaturedProviders = 'insurance_cta_with_providers'
}

export enum BehaviorCmsComponents {
  NeedAdviceBanner = 'need_advice_banner'
}

export enum SenseCmsComponents {
  NeedAdviceBanner = 'need_advice_banner',
  BodyLanguageBanner = 'sense_body_language_banner',
  OtherSignsBanner = 'sense_other_signs_banner',
  SafetyTipsBanner = 'sense_safety_tips_banner'
}

// Direct mappings to ContentCta records in wagcontent
export enum ContentCtas {
  VetChat = 'vet_chat',
  PetInsurer = 'pet_insurer',
}

export enum DateFormats {
  MonthDayYear = 'MM/dd/yyyy',
  MonthYear = 'MMM yyyy',
}

// Direct mappings to AnimalType records in wagcontent. See: https://github.com/waglabs/wagcontent/blob/develop/wagcontent/animal_taxonomy/constants.py
export enum AnimalTypeSlug {
  Cat = 'cat',
  Dog = 'care',
}

export enum CityPages {
  MinimumProfiles = 2,
  DogWalkingMinimumPrice = 23.50,
  DogWalkingMaximumPrice = 32.88,
  DogBoardingMinimumPrice = 48,
  DogBoardingMaximumPrice = 69,
  DogBoardingHomeTypeHouse = 'lives_in_house',
  DogBoardingHomeTypeApartment = 'lives_in_apartment',
  DogBoardingHomeTypeAnyType = 'any',
  DogTrainingMinimumPrice = 60,
  DogTrainingMaximumPrice = 100,
  DogGroomingMinimumPrice = 50,
  DogGroomingMaximumPrice = 100,
  OrganicProfilesPerPage = 20,
  PromotedProfilesPerPage = 5,
  DefaultWalkerDistance = 10, // Miles
  MaxWalkerDistance = 50, // Miles
  ReviewsPerPage = 10,
  DefaultSortColumn = 'recommended'
}

export enum WebEnvironments {
  Development='development',
  Staging='staging',
  Production='production',
}

export enum StatusCodes {
  Conflict=409,
}

export enum PaymentTypes {
  ApplePay = 'apple-pay',
  CreditCard = 'credit-card',
}

export enum Emails {
  Partnerships = 'partnerships@wagwalking.com'
}

export enum Mapbox {
  CustomStylesPath = 'mapbox://styles/thopma/cl7hh56hd001q14ocf6io85gd',
  StyleSheetDomain = 'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css',
  StylesheetId = 'mapbox-rel-stylesheet',
  Geocoding = 'https://api.mapbox.com/geocoding/v5/mapbox.places/{{location}}.json',
}



// wag-gatsby constants
export const HOMEPAGE_HERO_SECTION = 'homepage-hero-section';
export type ProductGuidesPages = 'reviews' | 'best-products';

type WagWebProductGuidesPageUrlParams = {
  page: ProductGuidesPages;
  slug: string;
  type?: string;
};


export const WAGWEB_PRODUCT_GUIDES_URL = ({ page, slug, type = 'dog-food' }: WagWebProductGuidesPageUrlParams) => `${BASE_URL}/product-guides/${page}/${type}/${slug}`;
export const WAGWEB_BOOKING_URL = `${BASE_URL}/on-demand`;

