import { BaseTheme as BaseWagTheme } from '../wag-react/themes/web-app-theme';
import { theme as BaseTheme } from '../wag-react/themes/base-theme';

// Loading the fonts this way will duplicate the requests
// The `woff2` should be the first one to load after `local()`
// then followed by `woff`
// What this basically means is that, if the browser found it exists on locally
// Then it will skip loading the rest of the files. If the browser sees that the
// browser supports woff2, it will skip the rest (https://caniuse.com/woff2)
// However, in this approach, since we're loading it through JS, it adds extra
// script evaluation and it actually loads all fonts + it is delayed (Resulting to FOUT)

const fonts = {
  // TODO: deprecate in favor of `muli`
  body: 'Muli, Helvetica, sans-serif, system-ui',
  muli: 'Muli, Helvetica, sans-serif, system-ui',
  muliBold: 'Muli Bold, Helvetica, sans-serif, system-ui',
  muliSemiBold: 'Muli SemiBold, Helvetica, Arial, sans-serif',
};

const fontSizes = [
  ['desktop', 'h1', 60], // alias: desktop-h1
  ['desktop', 'h2', 48], // alias: desktop-h2
  ['desktop', 'h3', 30], // alias: desktop-h3
  ['desktop', 'h4', 24], // alias: desktop-h4
  ['desktop', 'h5', 20], // alias: desktop-h5
  ['desktop', 'body1', 18], // alias: desktop-body1
  ['desktop', 'body2', 16], // alias: desktop-body2
  ['desktop', 'legal', 14], // alias: desktop-legal

  ['tablet', 'h1', 36], // alias: tablet-h1
  ['tablet', 'h2', 30], // alias: tablet-h2
  ['tablet', 'h3', 24], // alias: tablet-h3
  ['tablet', 'h4', 20], // alias: tablet-h4
  ['tablet', 'h5', 18], // alias: tablet-h5
  ['tablet', 'body1', 18], // alias: tablet-body1
  ['tablet', 'body2', 16], // alias: tablet-body2
  ['tablet', 'legal', 14], // alias: tablet-legal

  ['mobile', 'h1', 30], // alias: mobile-h1
  ['mobile', 'h2', 24], // alias: mobile-h2
  ['mobile', 'h3', 20], // alias: mobile-h3
  ['mobile', 'h4', 18], // alias: mobile-h4
  ['mobile', 'h5', 16], // alias: mobile-h5
  ['mobile', 'body1', 16], // alias: mobile-body1
  ['mobile', 'body2', 14], // alias: mobile-body2
  ['mobile', 'legal', 12], // alias: mobile-legal
];

const imperativelyExtractFontSizeAlias = (themeFontSizes = []) => {
  let newFontSizes = themeFontSizes.map(([, , fontSize]) => fontSize);

  themeFontSizes.forEach((themeFontSize) => {
    const [platform, semantic, fontSize] = themeFontSize;

    // see alias
    const alias = [platform, semantic].join('-');

    newFontSizes = Object.defineProperty(newFontSizes, alias, {
      value: fontSize,
    });
  });
  return newFontSizes;
};

const breakpoints = [
  '640px', // 40em
  '832px', // 52em
  '1920px', // 120em
  '2560px', // 160em
];

const colors = {
  ...BaseWagTheme.colors,
  ...BaseTheme.colors, // TODO: Migrate all of base theme properties rather than just "colors"
};

export const theme = {
  ...BaseWagTheme,
  breakpoints,
  colors,
  fonts,
  fontSizes: imperativelyExtractFontSizeAlias(fontSizes),
};
