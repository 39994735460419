import React from 'react';

import styled from 'styled-components';

export type SegmentScriptProps = {
  segmentKey: string;
  initOnMount?: boolean;
  windowPropertyName?: string;
  onLoadEventName?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
};

export const SegmentScript = ({
  segmentKey,
  initOnMount = true,
  windowPropertyName = 'analytics',
  onLoadEventName = 'segmentLoaded',
  ...rest
}: SegmentScriptProps) => (
  <Script
    id="segment-script"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: `!(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error("Segment snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var t = analytics.methods[e];
              analytics[t] = analytics.factory(t);
            }
            analytics.load = function (e, t) {
              var n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src =
                "https://cdn.segment.com/analytics.js/v1/" + e + "/analytics.min.js";
              n.onerror = function (err) {
                // Instead of printing an error from the useSegment.js
                // which is a false positive, print here if there's really
                // an error loading segment
                console.error('Failed to retrieve Segment instance', err);
              };

              n.onload = function () {
                const event = new Event('${onLoadEventName}');
                window.dispatchEvent(event);
              };
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = t;
            };
            analytics.SNIPPET_VERSION = "4.1.0";
            ${initOnMount && `
              analytics.load("${segmentKey}");
            `}
            window['${windowPropertyName}'] = analytics;
          }
      })();
      `,
    }}
    {...rest}
  />
);

/**
 * Create a Script component from styled-components so we can use
 * the "as" prop from styled-components
 * Consumers can use the Script component from NextJS or just the
 * native script element
 */
const Script = styled.script``;
