const deprecatedColors = {
  /**
   * @deprecated Use `buttermilk` as the naming is more aligned with the design
   */
  backgroundYellow: '#FAE7A8',
  /**
   * @deprecated Use `buttermilk` as the naming is more aligned with the design
   */
  champagne: '#FAE7A8',
  /**
   * @deprecated Use `kournikova` as the naming is more aligned with the design
   */
  copyYellow: '#F9CD46',
  /**
   * @deprecated Use `gulfBlue` as the naming is more aligned with the design
   */
  darkPurple: '#353F5B',
  /**
   * @deprecated Use `jewel` as the naming is more aligned with the design
   */
  darkGreen: '#085E45',
  /**
   * @deprecated Use `charcoal` as the naming is more aligned with the design
   */
  gray28: '#474747',
  /**
   * @deprecated Use `shingleFawn` as the naming is more aligned with the design
   */
  lightBrown: '#7B5533',
  /**
   * @deprecated Use `dimGray` as the naming is more aligned with the design
   */
  medGray: '#727272',
  /**
   * @deprecated Use `amaranth` as the naming is more aligned with the design
   */
  medRed: '#DD364A',
  /**
   * @deprecated Use `mySin` as the naming is more aligned with the design
   */
  wagGold: '#FAAB43',
  /**
   * @deprecated Use `blackRose` as the naming is more aligned with the design
   */
  darkRed: '#51272F',
  /**
   * @deprecated Use `freeSpeechAquamarine` as the naming is more aligned with the design
   */
  medGreen: '#009972',
  /**
   * @deprecated Use `toryBlue` as the naming is more aligned with the design
   */
  medPurple: '#3C5180',
  /**
   * @deprecated Use `summerSky` as the naming is more aligned with the design
   */
  mapBlue: '#1FAEE3',
  /**
   * @deprecated Use `coral` as the naming is more aligned with the design
   */
  orange: '#FF794D',
  /**
   * @deprecated Use `funBlue` as the naming is more aligned with the design
   */
  wagBlue: '#3A5080',
  /**
   * @deprecated Use `darkGray` as the naming is more aligned with the design
   */
  wagGray: '#AAAAAA',
  /**
   * @deprecated Use `caribbeanGreen` as the naming is more aligned with the design
   */
  wagGreen: '#00BF8F',
  /**
   * @deprecated Use `waikawaGrey` as the naming is more aligned with the design
   */
  wagPurple: '#616A9B',
  /**
   * @deprecated Use `wildWatermelon` as the naming is more aligned with the design
   */
  wagRed: '#FF5867',
} as const;

/**
 * You can get the hex name on these sites:
 * https://www.color-hex.com/color-names.html / https://www.htmlcsscolor.com/
 * These are the sites that the dev and design team agreed to use
 */
const baseColors = {
  deprecatedColors,
  ...deprecatedColors,
  aluminium: '#818A92',
  amaranth: '#DD364A',
  backgroundGray: '#EDEDED',
  black: '#000000',
  blackRose: '#51272F',
  boneWhite: '#E5E0D3',
  buttermilk: '#FAE7A8',
  caribbeanGreen: '#00BF8F',
  catalinaBlue: '#1A2C57',
  charcoal: '#474747',
  chetwodeBlue: '#6B7CAF',
  coral: '#FF794D',
  cyan: '#26BFC9',
  darkGray: '#AAAAAA',
  dimGray: '#727272',
  freeSpeechAquamarine: '#009972',
  freeSpeechRed: '#BF0202',
  funBlue: '#3A5080',
  gainsboro: '#D8D8D8',
  gray95: '#F2F2F2',
  gulfBlue: '#353F5B',
  iconBlue: '#5082E5',
  jewel: '#085E45',
  kournikova: '#F9CD46',
  lightGrey: '#D1D1D1',
  lightPurple: '#7786B7',
  magnolia: '#FEF9E4',
  mariner: '#4669B2',
  mediumTurquoise: '#3FD6CF',
  mistOfGreen: '#E5F2EE',
  mySin: '#FAAB43',
  red: '#ff0000',
  roseTonic: '#FFDEDE',
  shingleFawn: '#7B5533',
  summerSky: '#1FAEE3',
  toryBlue: '#3C5180',
  tuftsBlue: '#3798E4',
  transparent: 'transparent',
  waikawaGrey: '#616A9B',
  whisper: '#E5E5E5',
  white: '#FFFFFF',
  whiteSmoke: '#F5F5F5',
  wildWatermelon: '#FF5867',
} as const;

const breakpoints = [
  '640px', // 40em
  '832px', // 52em
  '1920px', // 120em
  '2560px', // 160em
] as const;

const colors = {
  ...baseColors,
} as const;

const fonts = {
  muli: 'Muli, Helvetica, Arial, sans-serif',
  muliBold: 'Muli Bold, Helvetica, Arial, sans-serif',
  muliSemiBold: 'Muli SemiBold, Helvetica, Arial, sans-serif',
} as const;

const fontSizes = [
  ['desktop', 'h1', 60], // alias: desktop-h1
  ['desktop', 'h2', 48], // alias: desktop-h2
  ['desktop', 'h3', 30], // alias: desktop-h3
  ['desktop', 'h4', 24], // alias: desktop-h4
  ['desktop', 'h5', 20], // alias: desktop-h5
  ['desktop', 'body1', 18], // alias: desktop-body1
  ['desktop', 'body2', 16], // alias: desktop-body2
  ['desktop', 'legal', 14], // alias: desktop-legal

  ['tablet', 'h1', 36], // alias: tablet-h1
  ['tablet', 'h2', 30], // alias: tablet-h2
  ['tablet', 'h3', 24], // alias: tablet-h3
  ['tablet', 'h4', 20], // alias: tablet-h4
  ['tablet', 'h5', 18], // alias: tablet-h5
  ['tablet', 'body1', 18], // alias: tablet-body1
  ['tablet', 'body2', 16], // alias: tablet-body2
  ['tablet', 'legal', 14], // alias: tablet-legal

  ['mobile', 'h1', 30], // alias: mobile-h1
  ['mobile', 'h2', 24], // alias: mobile-h2
  ['mobile', 'h3', 20], // alias: mobile-h3
  ['mobile', 'h4', 18], // alias: mobile-h4
  ['mobile', 'h5', 16], // alias: mobile-h5
  ['mobile', 'body1', 16], // alias: mobile-body1
  ['mobile', 'body2', 14], // alias: mobile-body2
  ['mobile', 'legal', 12], // alias: mobile-legal
];

const fontWeights = [
  100, // 0
  200, // 1
  300, // 2
  400, // 3
  500, // 4
  600, // 5
  700, // 6
  800, // 7
  900, // 8
] as const;

const radii = [
  0, // 0
  2, // 1
  4, // 2
  6, // 3
  8, // 4
] as const;

const space = [
  0, // 0
  8, // 1
  16, // 2
  24, // 3
  32, // 4
  40, // 5
  48, // 6
  56, // 7
  64, // 8
  72, // 9
  80, // 10
  88, // 11
  96, // 12
  104, // 13
  112, // 14
  120, // 15
] as const;

export const createFontSizesAlias = (themeFontSizes = []) => {
  let newFontSizes = themeFontSizes.map(([, , fontSize]) => fontSize);

  themeFontSizes.forEach((themeFontSize) => {
    const [platform, semantic, fontSize] = themeFontSize;

    // see alias
    const alias = [platform, semantic].join('-');

    newFontSizes = Object.defineProperty(newFontSizes, alias, {
      value: fontSize,
    });
  });
  return newFontSizes;
};

export const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes: createFontSizesAlias(fontSizes),
  fontWeights,
  radii,
  space,
} as const;
