import {
  canUseDOM,
  canUseViewport,
  canUseWindow,
} from './core';

type ExecutionEnvironment = Record<
  'isBrowser' | 'isSSR' | 'canUseDOM' | 'canUseViewport' | 'canUseWindow',
  boolean
>;

export const executionEnvironment: ExecutionEnvironment = {
  isBrowser: canUseDOM,
  isSSR: !canUseWindow,
  canUseDOM,
  canUseViewport,
  canUseWindow,
};
export {
  canUseDOM,
  canUseViewport,
  canUseWindow,
};
