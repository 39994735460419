import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { canUseWindow } from '../../../common/xenv';
import { pageTrack } from '../utils';

const checkIfSegmentAvailable = () => (
  canUseWindow && typeof window.analytics !== 'undefined'
);
export type SegmentOptions = {
  category?: string;
  name?: string;
  properties?: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: Record<string, any>;
  delayTracking?: number;
  onLoadEventName?: string;
};

/**
 * Call window.analytics.page() on every page transition
 *  via page-refresh or client-side transitions
 * We verify first if the Segment script is loaded using the eventListener
 * then we use that to determine that Segment is already "ready".
 * This prevents the error in calling window.analytics.page() if the Segment isn't ready/loaded yet
 */
export const useSegmentPageTransition = (
  currentPath: string,
  options: SegmentOptions = {},
) => {
  const [isSegmentLoaded, setIsSegmentLoaded] = useState(() => checkIfSegmentAvailable());
  const timeoutRef = useRef<number | null>(null);
  /**
   * Store the options in the ref to avoid infinite loop
   */
  const optionsRef = useRef(options);
  const loadEventName = optionsRef.current.onLoadEventName || 'segmentLoaded';

  const executePageTrack = () => {
    /**
     * For applications that uses <Helmet> on nested routes (owner-webapp), we will
     * also try to fetch the name from the <title data-segment-page-name="">...</title> element
     * before falling back to empty string.
     * This works best with using the `delayTracking` prop
     */

    pageTrack(
      optionsRef.current.category,
      optionsRef.current.name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      optionsRef.current.properties as any,
      optionsRef.current.options,
    );
  };

  const cleanup = () => {
    if (!timeoutRef.current) {
      return;
    }

    clearTimeout(timeoutRef.current);
  };

  /**
   * useEffect to listen for the event `segmentLoaded` which will be emitted
   * by the Segment script onLoad
   */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    if (isSegmentLoaded) {
      return undefined;
    }

    window.addEventListener(loadEventName, () => {
      setIsSegmentLoaded(checkIfSegmentAvailable());
    });

    return () => {
      window.removeEventListener(loadEventName, () => undefined);
    };
  }, [
    isSegmentLoaded,
    loadEventName,
  ]);

  /**
   * Call window.analytics.page()
   */
  useEffect(() => {
    if (!isSegmentLoaded) {
      return cleanup;
    }

    if (optionsRef.current.delayTracking) {
      timeoutRef.current = setTimeout(() => {
        executePageTrack();
      }, optionsRef.current.delayTracking) as unknown as number;

      return cleanup;
    }

    executePageTrack();

    return cleanup;
  }, [
    isSegmentLoaded,
    currentPath,
  ]);
};
