import {
  useRouter as useNextRouter,
  NextRouter,
} from 'next/router';

import {
  parseUrl,
  stringifyUrl,
  ParsedQuery,
} from 'query-string';

import {
  decamelizeKeys,
} from 'humps';
import { executionEnvironment } from '../wag-react/common/xenv';

/**
 * This is a simple wrapper of the Next.js' router hook
 * The reason we wrap this is because the inability to
 * get the parsed url without query paramaters.
 *
 * For a dynamic url, the returned url is like this:
 * route = /condition/[slug]
 * pathname = /condition/[slug]
 * asPath = /condition/muscle-spasms?page=1
 *
 * Since we want the url without query paramaters,
 * we will use the `window` object to do that.
 * Concatenating `window.origin` and `window.pathname`
 * will get the job done
 *
 * url = http://localhost:3000/condition/muscle-spasms
 */
export type Router = {
  url: string;
  path: string;
} & NextRouter & {
  location: Location;
  updateQueryString: (queryParams: ParsedQuery, fragmentIdentifier?: string) => Promise<boolean>;
  addHash: (hash: string) => void;
};

export const useRouter = (): Router => {
  const router = useNextRouter();

  const { asPath } = router;
  const path = asPath.includes('?') ? asPath.split('?')[0] : asPath;

  if (executionEnvironment.isSSR) {
    return {
      ...router,
      path,
      url: '',
      location: undefined,
      updateQueryString: () => Promise.resolve(false),
      addHash: () => undefined,
    };
  }

  const {
    origin,
    pathname,
  } = window.location;

  const url = [origin, pathname].join('');

  const updateQueryString = (queryParams = {}, fragmentIdentifier?: string) => {
    // https://stackoverflow.com/questions/10302179/hyphen-underscore-or-camelcase-as-word-delimiter-in-uris
    const serializedQueryParams = decamelizeKeys(queryParams) as ParsedQuery<string>;

    const parsedUrl = parseUrl(router.asPath, {
      parseFragmentIdentifier: true,
    });
    const newUrl = stringifyUrl({
      url: parsedUrl.url,
      query: serializedQueryParams,
      fragmentIdentifier: fragmentIdentifier || parsedUrl.fragmentIdentifier,
    });
    return router.replace(router.pathname, newUrl, { shallow: true });
  };

  const addHash = (hash: string) => {
    window.location.hash = `#${hash}`;
  };

  return {
    ...router,
    path,
    url,
    location: window.location,
    updateQueryString,
    addHash,
  };
};
