import '../theme/tailwind.css';
import React from 'react';

import { AppProps } from 'next/app';
import Script from 'next/script';

// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid';
import {
  theme,
} from '../theme';
import { SEGMENT_KEY } from '../constants/app';

import { useRouter } from '../hooks';
import { SegmentScript, useSegmentPageTransition } from '../wag-react/components/segment';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { Layout } = Component as AppProps['Component'] & { Layout?: React.FC<React.PropsWithChildren<unknown>> };

  // This implements the default behavior from styled-components v5
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  }
  const router = useRouter();
  useSegmentPageTransition(router.asPath);

  return Layout ? (
    <Layout
      {...pageProps}
    >
      <SegmentScript
        as={Script}
        segmentKey={SEGMENT_KEY}
      />
      <Component
        {...pageProps}
      />
    </Layout>
  ) : (
    <StyleSheetManager
      shouldForwardProp={shouldForwardProp}
    >
      <ThemeProvider
        theme={theme}
      >
        <SegmentScript
          as={Script}
          segmentKey={SEGMENT_KEY}
        />
        <Component
          {...pageProps}
        />
      </ThemeProvider>
    </StyleSheetManager>
  );
};
export default MyApp;
