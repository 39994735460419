export const pageTrack = (
  category?: string,
  name?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>,
  options?: SegmentAnalytics.SegmentOpts,
) => {
  /**
   * For applications that uses <Helmet> on nested routes (owner-webapp), we will
   * also try to fetch the name from the <title data-segment-page-name="">...</title> element
   * before falling back to empty string.
   * This works best with using the `delayTracking` prop
   */
  const segmentName = name
    || document.querySelector('title')?.getAttribute(
      'data-segment-page-name',
    ) || '';

  window.analytics.page(
    category,
    segmentName,
    properties,
    options,
  );
};
